$search-plp-top-banner-category-cover-display: block;
$search-plp-top-banner-color: color(dark);
$search-plp-top-banner-color--large: color(light);
$search-plp-top-banner-category-name-with-category-cover-color: color(dark);
$search-plp-top-banner-category-description-display: none;
$search-plp-top-banner-category-name-with-category-cover-display: none;
$search-plp-top-banner-text-align: left;
$search-plp-top-banner-category-name-margin: rem-calc(17 12 2);

$search-plp-top-banner-category-name-display: inline-block;
$search-plp-top-banner-results-count-display: inline-block;
$search-plp-top-banner-category-name-margin: rem-calc(25 5 25 25);
$search-plp-top-banner-results-count-margin: 0;
$search-plp-top-banner-results-count-color: color(secondary);
$search-plp-top-banner-results-count-font: 500 #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$search-plp-top-banner-category-name-font: 500 #{rem-calc(14)} / #{rem-calc(20)} $font-primary;
$search-plp-top-banner-color: color(secondary);

@import "@lora/05-components/search/search-plp-top-banner";
