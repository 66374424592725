$search-refinements-category-name-font: 500 #{rem-calc(14)} / #{rem-calc(20)} $font-primary-medium;
$search-refinements-category-name-padding: rem-calc(12 0 16);
$search-refinements-category-name-color: color(text);
$search-refinements-header-title-font--large: 300 #{rem-calc(12)} / #{rem-calc(16)} $font-secondary;
$search-refinements-header-title-text-transform--large: none;
$search-refinements-header-back-and-results-font: #{rem-calc(12)} / #{rem-calc(16)} $font-secondary;
$search-refinements-header-title-font: 500 #{rem-calc(14)} / #{rem-calc(20)} $font-primary-medium;
$search-refinements-header-count-border-radius--large: 0%;
$search-refinements-header-count-background--large: color(text);
$search-refinements-header-padding: rem-calc(0 20);

@import "@lora/05-components/search/search-refinements";