$form-radius: 0;
$form-legend-font-size: rem-calc(10);
$form-legend-font-weight: 300;
$form-legend-margin: rem-calc(5 0 15 0);
$form-field-separator-border-bottom: none;
$form-spacing-bottom: rem-calc(20);
$form-row-title-font-size: rem-calc(14);
$form-row-title-margin: rem-calc(0 0 15);
$form-row-title-color: color(organism-background);
$form-field-separator-padding: 0;

@import "@lora/05-components/form/form";
